import React from "react"

import Layout from "../components/layout/layout"
import ContactSectionNew from "../components/sections/contact-section-new"
import Seonew from "../components/seo-new"

const ContactPage = () => (
  <Layout>
    <Seonew title="Contact Us" />
    <ContactSectionNew />
  </Layout>
)

export default ContactPage
